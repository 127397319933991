.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-image: url(https://papers.co/wallpaper/papers.co-mu40-mountain-fog-nature-dark-bw-gray-sky-view-35-3840x2160-4k-wallpaper.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .logo {
    width: 100%;
    max-width: 700px;
    margin-bottom: 60px;
  }

  .grayscale-button {
    margin-bottom: 40px;
    padding: 0;
    background-color: #38383880;
    outline: 1px solid #9c9c9c;

    .grayscale-button-text {
      padding: 6px 16px;
      text-decoration: none;
      font-family: 'Questrial';
      font-size: 15px;
      color: whitesmoke;
      font-style: normal;
      font-variant: normal;
    }
  }

  .grayscale-button:disabled a {
    color: #9c9c9c;
  }

  .grayscale-button:hover {
    background-color: #4d4d4d;
  }

  .nav-button {
    margin: 0;

    .nav-button-text {
      font-family: 'Questrial';
      color: whitesmoke;
      font-style: normal;
      font-variant: normal;
    }
  }

  .vertical-divider {
    background-color: #9c9c9c;
    width: 1px;
  }
}